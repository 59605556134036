<template>
    <div class="card">
        <div class="logo">
            <img class="logoImg" src="@/assets/images/logo/ClinicaFamiliar.svg" alt="logo">
        </div>
        <!-- <div class="divisor"></div> -->
        <div class="text">
            <div v-if="foundCampaign">
                <div v-if="stateCupon == 'idle'" class="defaultText">
                    <h1 class="title mt-1" v-if="!review">{{ $t("landingCupon.GetCuponOf") }}{{ type == 'value' ?
                        `$${price}` :
                        `${price}
                        %`}}</h1>
                    <h1 class="title mt-1" v-else>{{ $t("landingCupon.GetReview") }}{{ type == 'value' ? `$${price}` :
                        `${price}
                        %`}}</h1>
                    <b-input-group prepend="+1" class="mt-2 inputPhone">
                        <b-form-input :placeholder="$t('landingCupon.WritePhone')" v-model="formatPhone"
                            @keyup.enter="getCode" />
                    </b-input-group>
                    <b-form-checkbox v-model="status" class="mt-2 inputPhone">
                        {{ $t("landingCupon.IRead") }} <a
                            href="https://clinicavirgenguadalupe.com/es/terminos-y-condiciones-de-cupones/"
                            target="_blank">{{ $t("landingCupon.Terms") }}</a> {{ $t("and") }} <a
                            href="https://clinicavirgenguadalupe.com/es/politica-de-privacidad/" target="_blank">{{
                                $t("landingCupon.Policy") }}</a>.
                    </b-form-checkbox>
                    <button id="buttonCupon" class="mt-3 buttonTemplate" @click="getCode" :disabled="!status">
                        <span>
                            {{ $t("landingCupon.GetCupon") }}
                        </span>
                    </button>
                    <img v-if="review" class="reviewImg" src="@/assets/images/logo/landing_review.png" alt="logo">
                </div>
                <div v-else-if="stateCupon == 'sending'" class="defaultText">
                    <h1 class="title">{{ $t("landingCupon.Sending") }}...</h1>
                    <div class="mt-1">
                        <b-spinner variant="success" type="grow" label="Spinning" />
                    </div>
                </div>
                <div v-else-if="stateCupon == 'send'" class="defaultText">
                    <h1 class="title">{{ $t("landingCupon.Send") }}</h1>
                </div>
                <div v-else-if="stateCupon == 'ERROR'" class="defaultText">
                    <h1 class="title">{{ message }}</h1>
                </div>
            </div>
            <div v-else class="notFoundFather">
                <h1 style="text-align: center">{{ $t("landingCupon.NotFound") }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/services/admin/consults/usedCupons";
import { BInputGroup, BFormInput, BButton, BFormCheckbox, BModal, BSpinner } from "bootstrap-vue"

export default {
    components: {
        BFormInput,
        BButton,
        BFormCheckbox,
        BModal,
        BInputGroup,
        BSpinner,
    },
    data() {
        return {
            phone: '',
            price: "",
            type: "",
            foundCampaign: true,
            stateCupon: 'idle',
            status: false,
            message: "",
            review: false,
        }
    },
    computed: {
        formatPhone: {
            get() {
                let phone = this.phone;
                if (phone) {
                    phone = phone.toString().replace(/\D/g, "");
                    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
                    if (match) {
                        phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${match[3] ? "-" : ""
                            }${match[3]}`;
                    }
                    return phone;
                } else {
                    return "";
                }
            },
            set(val) {
                this.phone = val;
            },
        },
    },
    created() {
        axios.getCampaign({ campaign: this.$route.params.cupon }).then(({ registro: { value, type, is_review }, status, message }) => {
            if (!value) return this.foundCampaign = false
            this.review = is_review
            this.message = message
            this.price = value
            this.type = type
            if (status == "ERROR") return this.foundCampaign = "ERROR"
        }).catch(() => {
            return this.stateCupon = "ERROR"
        })
    },
    methods: {
        getCode() {
            if (this.phone) {
                this.stateCupon = 'sending'
                axios.getCupon({ campaign: this.$route.params.cupon, phone_number: `+1${this.phone}` }).then(({ status, message }) => {
                    this.message = message
                    if (status == "ERROR") return this.stateCupon = "ERROR"
                    return status == "OK" ? this.stateCupon = "send" : this.stateCupon = "ERROR"
                }).catch(() => {
                    return this.foundCampaign = false
                })
            }
        }
    },
}
</script>