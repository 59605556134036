<template>
  <div class="page-content">
    <div class="navbar-container">
      <b-navbar-nav class="ml-1">
        <Locale :public="true" />
      </b-navbar-nav>
    </div>
    <div class="main-content">
      <div style="font-size: 115%;">
        <div class="father">
          <cardCupon />
        </div>
        <p class="info">{{ $t("landingCupon.MoreInfo")}} <a href="tel:+19402172000" class="link-phone">(940) 217-2000</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import {BNavbarNav} from "bootstrap-vue"
import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue";
import cardCupon from "./cardCupon.vue"

export default {
  components: {
    cardCupon,
    BNavbarNav,
    Locale
  },
};
</script>

<style scoped>
@import './styles.css';
</style>
